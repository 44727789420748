import React, { useState } from "react";
import Select from "react-select";
import Acnavbar from "../components/Acnavbar";
import ProductDescription from "../components/ProductDescription";
import Footer from "../components/footer";
import Contact from "../components/Contact";
import Wrapper from "../components/Wrapper";
import Seo from "../shared/seo";
import { Col, Container, Row } from "react-bootstrap";
import filterIcon from "../../static/filter.webp";
import productCards from "../json/product-cards.json";
import "../assets/scss/index.scss";
import BlogCard from "../components/BlogCard";
import OtherServicesCard from "../components/OtherServicesCard";
import { Link, graphql, useStaticQuery } from "gatsby";
import { format } from 'date-fns';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css"

function Blog() {
  const data = useStaticQuery(graphql`
    query pagesQuery {
      allContentfulBlogArticle {
        edges {
          node {
            id
            title
            timeToRead
            slug
            description {
              description
            }
            createdAt
            author {
              designation
              name
              profileImage {
                file {
                  contentType
                  fileName
                  url
                }
              }
            }
            previewImage {
              id
              title
              file {
                url
              }
              gatsbyImageData(layout: FIXED)
            }
            categories {
              id
              title
              contentful_id
              slug
            }
            body {
              raw
            }
          }
        }
      }

      allContentfulCategory {
        edges {
          node {
            id
            title
            slug
            description {
              description
            }
          }
        }
      }
    }
  `);

  // Access the queried data
  const blogArticles = data.allContentfulBlogArticle.edges.map(
    (node: any) => node.node
  );

  const categoriesList = data.allContentfulCategory.edges.map(
    (node: any) => node.node
  );

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (selectedOptions: any) => {
    setSelectedOptions(selectedOptions);
  };
  const currentPosts = blogArticles.filter((blog: any) => {
    if (selectedOptions.length === 0) {
      return true; 
    } else {
      const blogOptions = blog?.categories?.map((audit: any) => audit.title);
      return selectedOptions.every((option) =>
        blogOptions?.includes(option.value)
      );
    }
  });

  return (
    <Wrapper>
      <Seo
        title="Driving Excellence: Corporate Success Strategies & Trends"
        description=
        "Discover excellence in business with our corporate blog. Unleash potential, explore innovation, and stay ahead for corporate success."
      />
      <div>
        <Acnavbar home={false} pathname={'blogs'} logo={""} logoLogic={false} />
        <ProductDescription
          topheader="BLOGS"
          header="Driving Excellence: Corporate Success Strategies & Trends"
          headerText="Welcome to our corporate blog, where we dive deep into the strategies and trends that are driving excellence in today's business landscape. Join us as we explore innovative approaches, share valuable insights, and inspire you to unleash the full potential of your organization. Stay informed, stay ahead, and embark on a journey towards corporate success with us."
          showButton={true}
        />
        <div className="py-lg-5 py-4">
          <Container>
            <div className="">
              <div className="fnt-lg-32 fnt-md-32 fnt-sm-20 lh-sm-22 fw-700 text-center">
                Insights, Innovation, and Inspiration: Unleashing the Power of
                Business Success
              </div>
              <div className="d-lg-flex justify-content-end mb-3 mt-3">
                <div className="col-12 col-lg-3 col-md-3 d-flex justify-content-end fnt-14">
                  <Select
                    isMulti
                    options={categoriesList.map((data: any) => ({
                      label: data.title,
                      value: data.title,
                    }))}
                    placeholder="Categories Filter"
                    value={selectedOptions} 
                    onChange={handleChange} 
                  />
                  <div className="align-items-center d-flex greenGoblinBackground justify-content-center rounded-end-3">
                    <LazyLoadImage effect="blur" src={filterIcon} alt="Filter Icon" className="px-3" />
                  </div>
                </div>
              </div>
            </div>
            <BlogCard data={currentPosts} />
          </Container>
        </div>
        <div className="common-background py-4 py-lg-5">
          <Container className="mt-2 mt-lg-0">
            <div className="fnt-lg-32 fw-700 lh-lg-44 mb-4 mb-lg-4 pb-lg-3 text-center text-white">
              Our other Categories
            </div>
            <div className="d-flex flex-wrap justify-content-lg-center">
              {categoriesList.map((data: any, index: number) => (
                <div
                  className="blog-padding backgroundColor-BlogCat border-0 blogs-link"
                  key={index}
                >
                  <Link to={`/blogs/category/${data.slug}`}>{data.title}</Link>
                </div>
              ))}
            </div>
          </Container>
        </div>

        <div className=" px-lg-5">
          <Container className="mx-lg-9 px-lg-7 py-4 py-lg-5">
            <h2 className="fnr-lg-32 fw-700 pb-3 pb-lg-4 text-center">
              Our Services
            </h2>
            <Row xs={1} sm={1} md={3} lg={3} className="pe-0">
              {productCards.map((othercard: any) => (
                <Col className="pb-4" key={othercard.id}>
                  <OtherServicesCard {...othercard} />
                </Col>
              ))}
            </Row>
          </Container>
        </div>
        <div className="common-background">
          <h2 className="nav-link-blue">Blank</h2>
        </div>
        <Contact />
        <Footer />
      </div>
    </Wrapper>
  );
}

export default Blog;